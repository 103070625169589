@import "styles/abstracts/index";
div.tile {
  padding-top: 2.5rem;
  padding-bottom: 1.25rem;
  gap: 20px;

  .heading {
    font-size: 1.875rem;
  }

  .desc {
    font-size: 1.125rem;
    color: #000b33;
    opacity: 0.7;

    :global(.blue-highlighted) {
      background: var(--blue-highlight);
    }
  }

  a {
    display: flex;
    gap: 8px;
    color: var(--blue-hyperlink);
    font-weight: bold;
    text-decoration: underline;
  }
}

@include md-down() {
  div.tile {
    padding-top: 1rem;

    h5.heading {
      font-size: 1.5rem;
    }

    p.desc {
      font-size: 1rem;
    }
  }
}
