@import "styles/abstracts/index";
.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $zMax;
  background-color: var(--white);
  width: 100%;
  max-height: 0;
  overflow: auto;
  transition: all ease-in-out 0.2s;

  &.open {
    max-height: 10000px;
    transition: all ease-in-out 0.2s;
  }
}

.megaMenuWrap {
  background-color: var(--white);
  width: 100%;
  max-height: 100vh;
  overflow: auto;
  position: relative;

  @include lg-down {
    padding: 20px;
  }
  .megaMenuMainContent {
    padding: 30px;
  }
}

.brandWrap {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  .buttonArea {
    padding: 2rem;
    background-color: var(--blue-light-bg);
    width: 55%;

    @include lg-down {
      display: none;
    }
  }
}

div.close {
  justify-content: flex-end;
  margin-bottom: 20px;

  .closeButton {
    font-weight: 400;
    @include lg-down {
      display: none;
    }
  }
}
