@import "styles/abstracts/index";
.card {
  box-shadow: var(--card-boxshadow, none);
  border-radius: var(--border-radius-lg);
  padding: var(--card-padding, 2.25rem 3.5rem 3.2rem);
  background: var(--white);

  @include md-down() {
    padding: 1.5rem 2rem 2rem;
  }
}
