@import "styles/abstracts/index";
$animation: all ease-in 0.3s;
$animation-close: all ease-out 0.2s;

.faq {
  border-bottom: 1px solid #f4f4f4;
  user-select: none;
  padding: 10px 40px 10px 40px;
  transition: $animation-close;

  .text {
    max-height: 0;
    overflow: hidden;
    transition: $animation-close;
    line-height: 1.8;
  }

  &.open {
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
    padding: 20px 40px 40px 40px;
    transition: $animation;
    background-color: var(--white);

    .text {
      max-height: 800px;
      overflow: hidden;
      transition: $animation;
      list-style-position: inside;
    }
  }
}

.titleContainer {
  font-weight: 600;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
}

.title {
  line-height: 4;
}

.open {
  .titleContainer {
    color: var(--blue);
    transition: all ease-in-out 0.2s;
  }
}

@include sm-down() {
  .faq {
    padding: 10px 20px 10px 20px;

    &.open {
      padding: 10px 20px 20px 20px;
    }
  }
}
