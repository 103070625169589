@import "styles/abstracts/index";
.spinner {
  width: var(--spinner-size);
  height: var(--spinner-size);
  border-top: 1px solid black;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
