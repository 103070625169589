@import "styles/abstracts/index";
.helper {
  font-weight: bold;
  color: #908f8f;
  margin-top: 4px;
}

.inputWrapper {
  position: relative;

  .button {
    position: absolute;
    right: 10px;
    top: 10px;
    // transform: translateY(-50%);
  }
}
