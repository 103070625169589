@import "styles/abstracts/index";
.h2 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}

@include md-down() {
  .h2 {
    font-size: 1.8rem;
  }
}

@include sm-down() {
  .h2 {
    font-size: 1.5rem;
  }
}
