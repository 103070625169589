@import "styles/abstracts/index";
.container {
  padding: 1.875rem 0;
  transition: all ease-in-out 0.3s;

  a {
    color: inherit;
    text-decoration: none;
  }

  @include md-down() {
    padding: 1.8rem 0;
  }

  @include sm-down() {
    padding: 1rem 0rem;
  }
}

.logo {
  cursor: pointer;
}

.logoHamContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include lg-down() {
    width: 100%;
  }
}

.content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem 5rem;

  @include lg-down() {
    gap: 0;
    flex-direction: column;
    align-items: flex-start;
  }
}

.contentInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  flex: 1;

  @include lg-down() {
    gap: 0;
    display: block;
    max-height: 100vh;
    width: 100%;
    overflow: auto;
  }
}

.navItems {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;

  @include lg-down() {
    flex-direction: column;
    align-items: stretch;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all ease-in-out 0.3s;
  }

  & li:not(:first-of-type) {
    margin-left: 2.75rem;

    @include lg-down() {
      margin-left: 0;
      margin-top: 1.8rem;
    }
  }

  &.ctaButtons > * {
    white-space: nowrap;

    @include lg-down() {
      margin-top: 1rem;
    }
  }

  .noUnderline {
    &::after {
      display: none;
    }
  }

  li {
    font-weight: 500;
    font-size: 1.2rem;
    color: var(--black-light);
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;

    &::after {
      content: '';
      position: absolute;
      top: 110%;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: var(--primary);
      opacity: 0;
      transition: all 0.3s ease;
      border-radius: var(--border-radius-xs);
    }

    &:hover {
      color: var(--black);

      &::after {
        opacity: 1;
      }
    }

    @include lg-down() {
      color: var(--black);
      padding-bottom: 10px;
      border-bottom: 2px solid var(--grey-light);
    }

    &:last-child {
      border-bottom: none;

      &::after {
        display: none;
      }
    }
  }
}

.onlyLgDown {
  display: none;

  @include lg-down() {
    display: block;
  }
}

.onlyLgUp {
  display: block;

  @include lg-down() {
    display: none !important;
  }
}

.tools {
  display: flex;
  align-items: center;
  gap: 8px;
}

@include lg-down() {
  .opened {
    &.container {
      box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);
      background: white;
      transition: all ease-in-out 0.3s;
    }

    .navItems {
      max-height: unset;
      opacity: 1;
      transition: all ease-in-out 0.3s;
    }
  }
}

.cart {
  margin: 0 1rem;
  font-size: 1.5rem;

  &.active {
    color: var(--blue);
  }
}

.resourcesCard.resourcesCard {
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--grey);

  @include md-down() {
    padding: 8px 0;
  }

  a {
    width: 100%;
  }

  div > a > div {
    padding: 15px 23px 17px;
    cursor: pointer;

    span {
      font-weight: 500;
      font-size: 1.2rem;
      color: var(--black-light);
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 110%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--primary);
        opacity: 0;
        transition: all 0.3s ease;
        border-radius: var(--border-radius-xs);
      }
    }

    &:hover {
      background-color: var(--grey-light);

      > span {
        color: var(--black);

        &::after {
          opacity: 1;
        }
      }
    }
  }
}
