@import "styles/abstracts/index";
.hamburger {
  --i-stroke-color: var(--stroke-color, #3B415F);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .bar {
    display: block;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    stroke: var(--i-stroke-color);
  }

  &.active {
    .bar:nth-child(1) {
      transform: translate(8px) rotate(45deg)
    }

    .bar:nth-child(2) {
      transform: translate(-9px, 8px) rotate(-45deg)
    }
  }
}
