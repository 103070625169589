@mixin sm-down() {
  @media only screen and (max-width: 424px) {
    @content;
  }
}

@mixin sm-and-down() {
  @media only screen and (max-width: 425px) {
    @content;
  }
}

@mixin md-up() {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin md-down() {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin lg-down() {
  @media only screen and (max-width: 1023px) {
    @content;
  }
}

@mixin lg-and-down() {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}

@mixin lg-up() {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}

@mixin xl-down() {
  @media only screen and (max-width: 1439px) {
    @content;
  }
}

@mixin xl-up() {
  @media only screen and (min-width: 1439px) {
    @content;
  }
}

@mixin xxl-down() {
  @media only screen and (max-width: 1996px) {
    @content;
  }
}

@mixin xxl-up() {
  @media only screen and (min-width: 1997px) {
    @content;
  }
}
@mixin md-lg() {
  @media only screen and (min-width: 768px) and (max-width: 1075px) {
    @content;
  }
}

@mixin xl-1xl {
  @media only screen and (min-width: 1439px) and (max-width: 1536px) {
    @content;
  }
}

@mixin lg-up-xl-down {
  @media only screen and (min-width: 1024px) and (max-width: 1439px) {
    @content;
  }
}

@mixin xs-down {
  @media only screen and (max-width: 360px) {
    @content;
  }
}
