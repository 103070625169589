@import "styles/abstracts/index";
div.head {
  padding-bottom: 10px;
  border-bottom: 2px solid var(--grey-light);
  justify-content: space-between;
  cursor: pointer;

  .content {
    column-gap: 18px;

    .heading {
      font-size: 1.2rem;
      font-weight: 600;
      color: var(--black);
    }

    .desc {
      padding: 5px 10px;
      background-color: #f9f9f9;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.71);

      @include lg-down {
        display: none;
      }
    }
  }

  .chevron {
    cursor: pointer;
    align-items: center;
    justify-content: center;
    transform: rotate(-180deg);
    transition: all 0.5s ease-in-out;

    &.chevOpen {
      transform: rotate(0);
    }
  }
}

.menuWrap {
  margin-top: 1.8rem;

  .contentWrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
    row-gap: 3.3rem;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    @include md-down {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 1.8rem;
    }

    .tile {
      column-gap: 20px;
      padding: 16px;
      display: flex;

      @include md-down {
        column-gap: 12px;
        padding: 0;
      }
      cursor: pointer;

      .content {
        .miniHead {
          color: var(--black);
          font-weight: 500;
        }

        p {
          font-size: 0.85rem;
          color: var(--black-light);
        }
      }

      &:hover {
        background-color: var(--grey-light);
      }
    }

    &.openState {
      margin-bottom: 4rem;
      max-height: 4000px;
      padding-top: 16px;
      transition: all 0.5s ease-in-out;
      opacity: 1;
      @include md-down {
        margin-bottom: unset;
      }
    }
  }
}
