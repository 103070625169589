@import "styles/abstracts/index";
.container {
  text-align: center;
  padding: 6rem 20px;
  overflow: hidden;
}

div.content {
  justify-content: center;
  align-items: center;
  max-width: 450px;
  margin: auto;
  gap: 1rem;
}

.textButton {
  font-weight: 400;
  text-decoration: underline;
}
