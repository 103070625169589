@import "styles/abstracts/index";
.h6 {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.8rem;
}

@include md-down(){
  .h6{
    font-size: 1rem;
  }
}
