@import "styles/abstracts/index";
.content {
  background: transparent !important;
  border: 0 !important;
  width: unset !important;

  & > :global(.chakra-popover__body) {
    padding: 0;
  }
}
