@import "styles/abstracts/index";
.notificationWrapper {
  position: relative;

  .icon {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 15px;
    height: 15px;
    background-color: var(--notification-bg);
    color: var(--notification-color);
    border-radius: 50%;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
