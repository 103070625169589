@import "styles/abstracts/index";
.h3 {
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 1.3;
}

@include md-down() {
  .h3 {
    font-size: 1.8rem;
  }
}

@include sm-down() {
  .h3 {
    font-size: 1.5rem;
  }
}
