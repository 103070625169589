@import "styles/abstracts/index";
.button {
  line-height: 1.2;
  padding: 0.7rem 2rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  transition: all ease-in-out 0.2s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: 1px solid var(--black-light);
  background: var(--white);

  &:hover {
    filter: brightness(90%);
    transition: all ease-in-out 0.2s;
  }

  &:active {
    filter: brightness(105%);
    transition: all ease-in-out 0.2s;
  }

  &:global(.xs) {
    padding: 0.5rem;
    font-size: 16px;
    font-weight: 700;
    width: max-content;
  }

  &:global(.sm) {
    padding: 0.6rem 1.8rem;
    font-size: 0.8rem;
  }

  &:global(.md) {
    padding: 0.85rem 1.8rem;
    font-size: 1rem;
  }

  &:global(.lg) {
    padding: 1.2rem 3rem;
    font-size: 1.2rem;
  }

  &.primary {
    color: var(--black);
    background: var(--yellow);
    border-color: var(--yellow);

    &.disabled {
      background-color: var(--grey);
      border-color: var(--grey);
      pointer-events: none;
      cursor: not-allowed;
    }

    &.danger {
      background-color: var(--red);
      border-color: var(--red);
      color: var(--white);
    }
  }

  &.default {
    border: 1px solid var(--black-light);
    background: var(--white);

    &.disabled {
      background-color: var(--grey);
      border-color: var(--grey);
      pointer-events: none;
      cursor: not-allowed;
    }

    &.danger {
      border-color: var(--red);
      color: var(--red);
    }
  }

  &.ghost {
    background: transparent;
    border: 1px solid var(--black-light);
    color: var(--black-light);

    &.disabled {
      background-color: var(--grey);
      border-color: var(--grey);
      pointer-events: none;
      cursor: not-allowed;
    }

    &.danger {
      border-color: var(--red);
      color: var(--red);
    }
  }

  &.blue {
    background: #4284ff;
    border: 1px solid var(--white);
    color: var(--white);

    &.disabled {
      background-color: var(--grey);
      border-color: var(--grey);
      color: var(--black-light);
      pointer-events: none;
      cursor: not-allowed;
    }

    &.danger {
      border-color: var(--red);
      color: var(--red);
    }
  }

  &.text {
    color: var(--black);
    background: transparent;
    border: 1px solid transparent;

    &.disabled {
      color: var(--grey);
      pointer-events: none;
      cursor: not-allowed;
    }

    &.danger {
      color: var(--red);
    }
  }

  &.icon {
    color: var(--black);
    background: transparent;
    border: 1px solid transparent;
    padding: 0.5rem;

    &:hover {
      background-color: var(--white);
    }

    &.danger {
      color: var(--red);
    }
  }

  &.rounded {
    border-radius: 2rem;

    &.disabled {
      background-color: var(--grey);
      border-color: var(--grey);
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  &.square {
    width: 36px;
    aspect-ratio: 1 / 1;
    padding: 0px;

    &.disabled {
      background-color: var(--grey);
      border-color: var(--grey);
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  &.outlined {
    background-color: unset;
    border: 1px solid var(--black);

    &.disabled {
      background-color: var(--grey);
      border-color: var(--grey);
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  &.block {
    width: 100%;
  }

  &.loading {
    pointer-events: none;
  }
}
