@import "styles/abstracts/index";
.backDrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 100%;
  right: 0;
  z-index: -1;
  width: 100%;
  background-color: var(--backdrop);
  opacity: 0;
  transition: opacity 0.2s ease-in-out, bottom 0s ease-in-out 0.2s;
}

.backDrop.show {
  bottom: 0;
  opacity: 1;
  transition: opacity 0.2s ease-in-out, bottom 0s ease-in-out 0s;
}
