@import "styles/abstracts/index";
.section {
  position: relative;
  background-image: url('/images/home/dots-bg.png');
}

.container {
  padding: 3rem 20px;
  display: flex;
  gap: 3rem;
  justify-content: space-between;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

@include md-down(){
  .grid{
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 40px;
  }
}


@include sm-down(){
  .grid{
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 40px;
  }
}
