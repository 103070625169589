@import "styles/abstracts/index";
.container {
  text-align: center;
  overflow: hidden;
}

.heading {
  margin-bottom: 1.875rem;
}

.tabWrapper {
  border: 1px solid var(--grey);
  border-radius: 4px;
  padding: 4px;
  margin: 0 auto;
  margin-bottom: 2.18rem;
  max-width: 1025px;
  white-space: nowrap;
  overflow-x: scroll;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  .tab {
    padding: 0 1.25rem;
    height: 44px;
    background-color: transparent;
    transition: background 0.3s ease-in-out;
    @include md-down {
      white-space: nowrap;
    }
    filter: none;
    .active,
    &:hover,
    &.active,
    &:focus {
      background-color: var(--yellow);
    }
  }
}

div.contentWrapper {
  padding: 2rem;
  margin-bottom: 4.435rem;
  height: 516px;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  background-image: url('/images/home/dots-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--hero-bg);
  border-radius: 4px;

  @include md-down {
    flex-direction: column;
    height: auto;
  }
  .container {
    width: 50%;
    position: relative;
    height: 100%;
    justify-content: flex-start;

    @include md-down {
      width: 100%;
    }

    .head {
      margin-bottom: 2rem;
      align-items: center;
      justify-content: flex-start;
      column-gap: 17px;
    }

    p {
      text-align: left;
      color: var(--black);
      padding-bottom: 1.5rem;
      border-bottom: 1px solid var(--grey);
    }

    .largeImageContainer {
      position: relative;
    }
    .arrows {
      position: absolute;
      bottom: 3rem;
      left: 10px;
      justify-content: flex-end;
      align-items: center;
      column-gap: 2.25rem;
      @include md-down {
        display: none;
      }
      .left,
      .right {
        cursor: pointer;
      }
      .right {
        transform: rotate(180deg);
      }
    }
  }

  .rightContainer {
    align-items: center;
  }
}
