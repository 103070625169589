@import "styles/abstracts/index";
.column{
  max-width: 120px;
  text-align: center;
  align-items: center;
  margin: 0 auto;
}

.dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: red;
}

.big {
  font-size: 3rem;
  font-weight: 700;
}

.small {
  font-size: 0.8rem;
  font-weight: 600;
}
