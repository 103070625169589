@import "styles/abstracts/index";
.section {
  position: relative;
  background-color: #eafaff;
  background-image: url('/images/home/dots-bg-blue.png');
}

.container {
  padding: 6rem 20px;
  display: flex;
  gap: 3rem;
  justify-content: space-between;
  overflow: hidden;
}

.left {
  position: relative;
  align-items: center;
  justify-content: center;
}

.leftImageContainer {
  max-width: 80%;
  margin: auto;
}

div.right {
  gap: 20px;
  max-width: 450px;
}

.underline {
  text-decoration: underline;
}

@include md-down() {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .leftImageContainer {
    max-width: 50%;
  }
}

@include sm-down() {
  .leftImageContainer {
    max-width: 80%;
  }
}
