$container-width: 1280px;
$container-width-xxl: 1500px;
$container-padding: 20px;

$yellow: #f9d783;
$primary: $yellow;
$red: #ff4b4b;
$green: #1aa703;
$orange: #ff8139;

$zMax: 10;
$zCookie: 9;

$auction-bid-card-height: 212px;
