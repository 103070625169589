@import "styles/abstracts/index";
.main {
  background: var(--hero-bg);
}

.container.container {
  display: flex;
  gap: 40px;
  padding: 6rem 20px;
  justify-content: space-between;
  overflow: hidden;
}

.subHeading {
  color: rgba(0, 0, 0, 0.8);
}

h6.topSubHeading {
  max-width: 60%;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.8rem;
  @include md-down() {
    font-size: 1rem;
  }
}

.ctaSection {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 40px 0 80px;

  @include xl-down() {
    margin: 30px 0 40px;
  }
}

.helper {
  color: var(--black-light);
}

@include lg-down() {
  .container {
    flex-direction: column;
  }

  h6.topSubHeading {
    max-width: 90%;
  }
}
