@import "styles/abstracts/index";
.h1 {
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 1.2;
}

@include xl-down() {
  .h1 {
    font-size: 3.5rem;
  }
}

@include md-down() {
  .h1 {
    font-size: 3rem;
  }
}

@include sm-down() {
  .h1 {
    font-size: 2.5rem;
  }
}
