@import "styles/abstracts/index";
$max-width: 560px;

.container {
  display: flex;
  justify-content: space-between;
  gap: 9rem;
  padding: 8rem 20px;
  overflow: hidden;
}

.featuresGrid {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  max-width: $max-width;
}

div.graphicContainer {
  max-width: $max-width;
  gap: 3rem;
  justify-content: center;
}

.sideImageContainer {
  max-width: 70%;
}

@include md-down() {
  .container {
    flex-direction: column;
    gap: 5rem;
    align-items: center;
  }

  .sideImageContainer {
    max-width: 50%;
  }

  div.graphicContainer {
    align-items: center;
  }
}

@include sm-down() {
  .featuresGrid {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    max-width: $max-width;
  }
}
