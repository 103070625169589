@import "styles/abstracts/index";
.container {
  background-color: var(--black);
  color: white;

  a {
    text-decoration: none;
    color: inherit;
    font-weight: normal;
  }
}

.content {
  padding: 8rem 20px;
}

.top {
  display: flex;
  align-items: flex-start;

  .left,
  .right {
    flex: 1;
  }

  .leftContent {
    max-width: 400px;
    p {
      margin-top: 4px;
    }
  }
}

.bottom {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 3rem;
  margin-top: 10rem;
  justify-content: space-between;
  align-items: flex-start;

  ul li {
    list-style: none;
    color: #adadad;
    margin-top: 8px;
  }

  .mt {
    margin-top: 2rem;
  }
}

.signature {
  font-weight: bold;
  margin-top: 5rem;
  color: var(--black-light);

  a {
    color: var(--blue-hyperlink);
    cursor: pointer;
    text-decoration: underline;
    font-weight: 600;
  }
}

@include md-down() {
  .top {
    flex-direction: column;
    gap: 3rem;
  }

  .bottom {
    margin-top: 5rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
