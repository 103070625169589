@import "styles/abstracts/index";
.section {
  padding: 48px 0;
  background-color: var(--hero-bg);
  background-image: url('/images/home/dots-bg.png');

  .container {
    overflow: hidden;

    svg {
      width: 100%;
      height: 100%;
    }

    .row {
      @include md-down() {
        flex-direction: column-reverse;
        justify-content: center;

        svg {
          max-width: 400px;
          margin: 0 auto;
        }
      }

      .info {
        .subtitle {
          display: block;
          font-size: 1.25rem;
          color: var(--blue);
          margin-bottom: 30px;

          @include lg-down() {
            margin-bottom: 20px;
          }
        }

        .title {
          font-size: 3rem;
          font-weight: 400;
          margin-bottom: 25px;
          white-space: nowrap;

          @include xl-down() {
            font-size: 2.5rem;
          }

          @include lg-down() {
            margin-bottom: 15px;
            font-size: 2rem;
          }

          @include md-down() {
            font-size: 1.5rem;
          }
        }

        .text {
          max-width: 350px;
          display: block;
          margin-bottom: 30px;

          @include lg-down() {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
