@import "styles/abstracts/index";
.inputWrapper {
  position: relative;

  .prefixWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 75px;
    height: 100%;
    background-color: var(--grey-normal);
    border-top-left-radius: var(--border-radius-xs);
    border-bottom-left-radius: var(--border-radius-xs);
    color: var(--white);
    font-weight: 700;
  }

  .defaultValuesWrapper {
    position: absolute;
    display: none;
    bottom: 0;
    transform: translateY(100%);
    left: 0;
    width: 100%;
    background-color: var(--white);
    border: 0.7px solid var(--grey);
    z-index: $zMax;
    max-height: 200px;
    overflow-y: auto;

    &.active {
      display: flex;
    }

    .defaultValue {
      padding: 0.5rem 1rem;
      width: 100%;
      cursor: pointer;

      &:hover {
        background-color: var(--primary);
      }
    }
  }
}

.input {
  border: 0.7px solid var(--grey);
  background: var(--white);
  border-radius: var(--border-radius-xs);
  padding: 0.5rem 1rem;
  width: 100%;
  outline: 0;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 40px rgba(158, 191, 255, 0.12);
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 171%;
  color: var(--black);

  &.lg {
    padding: 1rem 1.5rem;
    font-size: 1rem;
  }

  &.prefixed {
    padding-left: calc(75px + 1rem);
  }

  &::placeholder {
    color: var(--input-placeholder-color);
  }

  &:hover {
    border-color: var(--black-light);
  }

  &:focus {
    border-color: var(--black-dark);
  }

  &.error {
    border-color: var(--red);
  }

  &.disabled {
    background: var(--grey);
    pointer-events: none;
  }
}

.label {
  color: var(--black-dark);
  font-weight: 500;
}
